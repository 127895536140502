var Surveys = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        $templateSurveysItem,
        $templateSurveysStarRating,
        $templateSurveysStarGroupRating,
        $templateSurveysStarGroupRatingItem,
        $templateSurveysRadio,
        $templateSurveysRadioItem,
        $templateSurveysCheck,
        $templateSurveysCheckItem,
        $templateSurveysText,
        $templateSurveysHidden;
    var ar;
    var noRequiredItem = true;

    localState.surveys = localState.surveys || {};
    localState.surveys.itemIndex = localState.surveys.itemIndex || 0;

    /* ----- Init Surveys --------------------------------------------------------------------------------------------- */
    function Init() {
        $templateSurveysItem = $('template#tplSurveysItem').remove().contents();
        $templateSurveysStarRating = $('template#tplSurveysStarRating').remove().contents();
        $templateSurveysStarGroupRating = $('template#tplSurveysStarGroupRating').remove().contents();
        $templateSurveysStarGroupRatingItem = $templateSurveysStarGroupRating.find('template#tplSurveysStarGroupRatingItem').remove().contents();
        $templateSurveysRadio = $('template#tplSurveysRadio').remove().contents();
        $templateSurveysRadioItem = $templateSurveysRadio.find('template#tplSurveysRadioItem').remove().contents();
        $templateSurveysCheck = $('template#tplSurveysCheck').remove().contents();
        $templateSurveysCheckItem = $templateSurveysCheck.find('template#tplSurveysCheckItem').remove().contents();
        $templateSurveysText = $('template#tplSurveysText').remove().contents();
        $templateSurveysHidden = $('template#tplSurveysHidden').remove().contents();
        renderList();
        renderDetails();
        // AppData.
        delete Surveys.Init;
    }

    function sortSurveys(data) {
        data.sort(function(a,b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        })
    }
    /* ----- Build Attendees List ----------------------------------------------------------------------------------- */
    function renderList() {

        var
            $self = $('#surveys #surveysList'),
            $fragmentSurveys = $(document.createDocumentFragment()),
            data = AppData.surveys.filter(function (el) {
                return el.active === true;
            });
        sortSurveys(data);
        $.each(data, function (i, el) {
            if(el.active) {
                var $clone = $templateSurveysItem.clone();
                $clone.attr('data-index', el.id);
                $clone.find('.filledsurveydisplay').css('display', isFilledSurvey(el) ? 'block' : 'none');
                // $clone.find('.chevron').show();
                $clone.find('.chevron').css('display', isFilledSurvey(el) ? 'none' : 'block');
                $clone.find('.data-title').text(el.title[localState.lang]);
                $clone.appendTo($fragmentSurveys);
            }
        });
        $self.html($fragmentSurveys);
        $('#surveys .no-result').toggle(Boolean(!data.length));
    }


    function isFilledSurvey(el) {
        var surveysdata = AppData.surveysdata.filter(function(e) {
            return e.survey === el.id;
        });
        var answers_multiple_times = el.answers_multiple_times === undefined ? false : el.answers_multiple_times;
        return surveysdata.length > 0 && !answers_multiple_times;
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails() {
        if ('surveys' in localState) {
            var
                $details = $('#surveys-details article'),
                $header = $('#surveys-details .data-label'),
                $fragmentDetails = $(document.createDocumentFragment()),
                $clone,
                $cloneItem,
                el = AppData.surveys.find(function (el) {
                    return el.id === localState.surveys.itemIndex;
                }) || AppData.surveys[0],
                filled = AppData.surveysdata.find(function (el) {
                    return el.survey === localState.surveys.itemIndex;
                });

            if (!el) return false;
            filled = Boolean(filled) && !el.answers_multiple_times;
            $header.text(el.title[localState.lang]);
            $header.text(AppData.languages[AppData.settings.lang]['nav-surveys']);
            $details.find('.survey_title h3').html(el.title[localState.lang]);
            ar = new Array();

            $.each(el.parts, function (index, item) {

                let asterisk = '';
                if (item.answer_required && el.show_asterisk) {
                    asterisk = ' *';
                }

                switch (item.type) {
                    case 'grouprating':
                        $clone = $templateSurveysStarGroupRating.clone();
                        $clone.find('.data-title').text(item.question[localState.lang] + asterisk);
                        if(item.description !== undefined)
                            $clone.find('.data-description').html(item.description[localState.lang]).css('display', 'block');
                        if(item.answers) {
                            $.each(item.answers[localState.lang].split('||'), function (j, answer) {
                                $cloneItem = $templateSurveysStarGroupRatingItem.clone();
                                $cloneItem.find('.data-label').text(answer);
                                $cloneItem.find('input').attr("data-id", item.id); // .val(answer);
                                if(item.rating_star_count !== undefined && item.rating_star_count != '' && item.rating_star_count > 0) {
                                    for(var i = 0; i  < item.rating_star_count ; i++) {
                                        $cloneItem.find('.rating_icons').html($cloneItem.find('.rating_icons').html() + '<i class="far fa-fw fa-2x fa-star"></i>');
                                    }
                                }
                                $cloneItem.appendTo($clone);
                            });
                        }
                        $clone.find('.answer-required').val(String(item.answer_required));
                        $clone.find('input').attr('data-answer-required', String(item.answer_required));
                        $clone.appendTo($fragmentDetails);
                        if(item.answer_required)
                            if($.inArray('parts_' + index, ar) == -1)
                                ar.push('parts_' + index);
                        break;
                    case 'rating':
                        $clone = $templateSurveysStarRating.clone();
                        $clone.find('.data-title').text(item.question[localState.lang] + asterisk);
                        if(item.description !== undefined)
                            $clone.find('.data-description').html(item.description[localState.lang]).css('display', 'block');
                        $clone.find('.answer-required').val(String(item.answer_required));
                        $clone.find('input').attr("data-id", item.id);
                        $clone.find('input').attr('data-answer-required', String(item.answer_required));
                        $clone.appendTo($fragmentDetails);
                        if(item.rating_star_count !== undefined && item.rating_star_count != '' && item.rating_star_count > 0) {
                            for(var i = 0; i  < item.rating_star_count ; i++) {
                                 $clone.find('.rating_icons').html($clone.find('.rating_icons').html() + '<i class="far fa-fw fa-2x fa-star"></i>');
                             }
                        }
                        if(item.answer_required)
                            if($.inArray('parts_' + index, ar) == -1)
                                ar.push('parts_' + index);
                        break;
                    case 'radio':
                        $clone = $templateSurveysRadio.clone();
                        $clone.find('.data-title').text(item.question[localState.lang] + asterisk);
                        if(item.description !== undefined)
                            $clone.find('.data-description').html(item.description[localState.lang]).css('display', 'block');
                        if(item.answers) {
                            $.each(item.answers[localState.lang].split('||'), function (j, answer) {
                                $cloneItem = $templateSurveysRadioItem.clone();
                                $cloneItem.find('.data-label').text(answer);
                                $cloneItem.find('input').val(answer);
                                $cloneItem.appendTo($clone);
                            });
                        }
                        $clone.find('.answer-required').val(String(item.answer_required));
                        $clone.find('input').attr("data-id", item.id);
                        $clone.find('input').attr('data-answer-required', String(item.answer_required));
                        $clone.appendTo($fragmentDetails);
                        if(item.answer_required)
                            if($.inArray('parts_' + index, ar) == -1)
                                ar.push('parts_' + index);
                        break;
                    case 'check':
                        $clone     = $templateSurveysCheck.clone();
                        $cloneItem = $templateSurveysCheckItem.clone();
                        var isMinMax   = item.checkbox_min !== "" && item.checkbox_max !== "" && item.checkbox_min > 0 && item.checkbox_max > item.checkbox_min;
                        var isRequired = item.answer_required || isMinMax;
                        $clone.find('.data-title').text(item.question[localState.lang] + (isRequired ? asterisk : ''));
                        if(item.description !== undefined)
                            $clone.find('.data-description').html(item.description[localState.lang]).css('display', 'block');
                        if (item.answers) {
                            $.each(item.answers[localState.lang].split('||'), function (j, answer) {
                                $cloneItem = $templateSurveysCheckItem.clone();
                                $cloneItem.find('.data-label').text(answer);
                                $cloneItem.find('input').val(answer);
                                $cloneItem.find('input').addClass('parts_' + index);
                                if(isRequired) {
                                    $cloneItem.find('input').attr('data-name', 'parts_' + index );
                                    if ($.inArray('parts_' + index , ar) == -1) {
                                        ar.push('parts_' + index );
                                    }

                                }

                                $cloneItem.appendTo($clone);
                            });
                        }
                        $clone.find('.answer-required').val(String(item.answer_required));
                        $clone.find('input').attr("data-id", item.id);
                        $clone.find('input').attr('data-answer-required', String(item.answer_required));
                        $clone.find('input').attr('data-checkbox_min', String(item.checkbox_min));
                        $clone.find('input').attr('data-checkbox_max', String(item.checkbox_max));
                        $clone.appendTo($fragmentDetails);
                        break;
                    case 'text':
                        $clone = $templateSurveysText.clone();
                        $clone.find('.data-title').text(item.question[localState.lang] + asterisk);
                        if(item.description !== undefined)
                            $clone.find('.data-description').html(item.description[localState.lang]).css('display', 'block');
                        $clone.find('textarea').attr("data-id", item.id);
                        $clone.find('textarea').attr('placeholder', Language.getItem('write-here'));
                        $clone.find('textarea').attr('data-answer-required', String(item.answer_required));
                        $clone.find('.answer-required').val(String(item.answer_required));
                        $clone.appendTo($fragmentDetails);
                        if(item.answer_required)
                            if($.inArray('parts_' + index, ar) == -1)
                                ar.push('parts_' + index);
                        break;
                }
                noRequiredItem = noRequiredItem && !item.answer_required;
                $clone.find('input, textarea').attr('name', 'parts_' + index + '');
            });

            if(el.survey_description)
                $details.find('.survey_description').html(el.survey_description[localState.lang]).show();

            $details.find('.items').html($fragmentDetails);
            $details.find('[type=submit]').addClass('disabled');
            $details.find('.data-thank-you').html(el.thxtext[localState.lang]);
            $details.find('.data-thank-you').toggle(filled);
            $details.find('form').toggle(!filled);
            $clone = null;
        }
    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() {
        $.post('api/', {
            do: 'getSurveys'
        }, function (data) {
            AppData.surveys = data;
            $.post('api/', {
                do: 'getSurveysData'
            }, function (res) {
                AppData.surveysdata = res;
                renderList();
                renderDetails();
                res = null;
            });
            data = null;
        })
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        localState.surveys.itemIndex = String($(this).data('index')) || 0;
        if($(this).find(".filledsurveydisplay").is(":visible")) {
            writeDbLog("view filled", localState.surveys.itemIndex, "user_logs", "surveys");
        } else {
            writeDbLog("start fill",  localState.surveys.itemIndex, "user_logs", "surveys");
        }
        renderDetails();
        Layout.show('surveys-details');
    }

    /* ----- Reser Form ----------------------------------------------------------------------------------------------- */
    function resetForm() {
        var $survey = $('#surveys-details form');
        $survey.find('input[type=hidden]').val('');
        $survey.find('input[type=radio], input[type=checkbox]').prop('checked', false);
        $survey.find('label').removeClass('state-checked animated pulse');
        $survey.find('.fa-star').removeClass('fas').addClass('far');
        $survey.find('textarea').val('');
    }

    /* ----- Event: Change Input -------------------------------------------------------------------------------------- */
    function evtChangeInput() {
        var
            classList = 'state-checked animated pulse',
            $label = $(this).closest('label');
        if (this.type === 'radio') {
            $label.siblings().removeClass(classList);
        }

        if (this.type == "checkbox") {
            let max = parseInt($(this).attr("data-checkbox_max"));
            let checked = $(".state-checked", $(this).closest(".item")).length;
            if (checked == max) {
                $(this).prop("checked", false);
            }
        }
        $label.toggleClass(classList, this.checked);
    }

    /* ----- Event: Click Star ---------------------------------------------------------------------------------------- */
    function evtClickStar() {
        var $this = $(this);
        $this
            .prevAll().addBack()
            .removeClass('far').addClass('fas');
        $this
            .nextAll()
            .removeClass('fas').addClass('far');
        $this.parent().siblings('input').val($this.index() + 1).trigger('change');
    }

    /* ----- Event: Submit Form --------------------------------------------------------------------------------------- */
    function evtSubmitForm(e) {
        e.preventDefault();
        var
            $this = $(this),
            formData = $this.serializeObject();
        resetForm();

        let values = {};
        $.each($this.find("input, textarea"), function(i,e) {
            if(formData[e.name]) {
               values[e.name] = {"id": e.dataset.id, "type": e.type, "values": formData[e.name]};
            }
        });
        values.survey = localState.surveys.itemIndex;

        writeDbLog("submit", localState.surveys.itemIndex, "user_logs", "surveys");

        if (AppData.settings.start_survey == localState.surveys.itemIndex) {
            Layout.hide("surveys-details");
        }

        $this.hide();
        $this.closest('div.main').find('.data-thank-you').show();
        $this.closest('div.main').find('.survey_description').hide();

        $.post('api/', {
            do: 'saveSurveyData',
            data: values
        }, function (res) {
            AppData.surveysdata = res;
            renderList();
            res = null;
        });
    }

    /* ----- Event: Enable Submit on Input -------------------------------------------------------------------------- */
    function evtInput(e) {

        var validField,
            isMinMax = this.dataset.checkbox_min !== "" && this.dataset.checkbox_max !== "" && this.dataset.checkbox_min > 0 && this.dataset.checkbox_max > this.dataset.checkbox_min;

        switch (e.target.type) {
                case 'hidden':
                case 'radio':
                    validField = true;
                break;
                case 'textarea':
                    validField = e.target.value != '';
                    break;
                case 'checkbox':
                    var checkedCount = 0;
                    $.each($("#surveys-details").find(".items ." + this.name), function(i,e) {
                        validField = validField || e.checked;
                        if(e.checked) checkedCount++;
                    });

                    // console.log(checkedCount);

                    if(isMinMax && checkedCount < this.dataset.checkbox_min)
                        validField = false;
                    if(isMinMax && checkedCount > this.dataset.checkbox_max)
                        validField = false;
                    break;
            }

            if (this.dataset.answerRequired == "true" || isMinMax)
                if (validField) {
                    ar = arrayRemove(ar, this.name);
                } else {
                    ar.push(this.name);
                }

        $(this).closest('form').find('[type=submit]').toggleClass('disabled', Boolean(ar.length != 0) );

    }

    function arrayRemove(arr, value) {

        return arr.filter(function(ele){
            return ele != value;
        });

    }

    function showQuestion(id) {
        if (id) {
            //if (id !== localState.surveys.itemIndex || localState.surveys.itemIndex === 0) {
                localState.surveys.itemIndex = String(id);
                renderDetails();
            //}
            Layout.show('surveys-details');
        }
    }

    function showDetails(id) {
        if (id) {
            //if (id !== localState.surveys.itemIndex || localState.surveys.itemIndex === 0) {
                localState.surveys.itemIndex = String(id);
                renderDetails();
            //}
            Layout.show('surveys-details');
        }
    }

    /* ----- Bind Events -------------------------------------------------------------------------------------------- */
    $(document)
        .on('change', '#surveys-details input[type=radio], #surveys-details input[type=checkbox]', evtChangeInput)
        .on('click', '#surveys #surveysList .item', evtClick)
        .on('input', '#surveys-details form textarea', evtInput)
        .on('change', '#surveys-details form input', evtInput)
        .on('click', '#surveys-details .survey-star .fa-star', evtClickStar)
        .on('submit', '#surveys-details form', evtSubmitForm);
    /* ----- Public API --------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showQuestion: showQuestion,
        showDetails: showDetails,
        evtClickDetails: evtClick
    };
})();
