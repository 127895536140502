/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Issues = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateIssuesItem;
    var $templateOtherIssuesItem;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        $templateIssuesItem = $('template#tplIssuesItem').remove().contents();
        $templateOtherIssuesItem = $('template#tplOtherIssuesItem').remove().contents();
        renderList();
        renderDetails();

        maxCharacterCount();
        // delete Issues.Init;
    }

    /* ----- Build Docs List ---------------------------------------------------------------------------------------- */
    function renderList() {
        var
            $self = $('#issues .issuesList'),
            $fragmentNotes = $(document.createDocumentFragment());
        var own_issues = AppData.issues.filter(function (el) {
            return el.user === AppData.profile.id;
        });
        $.each(own_issues, function (i, el) {
            var $clone = $templateIssuesItem.clone();
            $clone.attr('data-index', el.id);
            $clone.find('.data-question').text(el.question);
            var status_text = Language.getItem('moderation-status-waiting');
            if (el.approved || el.client_approved) {
                status_text = Language.getItem('moderation-status-approved');
            }
            if(el.answer) {
                status_text += " - <b>" + Language.getItem('moderation-status-answered') + "</b>";
            }
            $clone.find('.data-status').html(status_text);
            $clone.appendTo($fragmentNotes);
        });

        $self.html($fragmentNotes);
        $('#issues').find('.no-result').toggle(Boolean(!AppData.issues.length));
        Moderate.renderList();

        if (AppData.modulsettings.qandausercanvote) {

            var
                vote_cont = $('#issues-vote .issuesVoteList'),
                $fragment = $(document.createDocumentFragment());


            $("#issues .vote-issues").show();
            var other_issues = AppData.issues.filter(function (el) {
                return el.user !== AppData.profile.id;
            });

            other_issues.sort(function (a, b){
                let aup = 0;
                let adown = 0;

                if (a.upvotes) {
                    aup = a.upvotes.length;
                }

                if (a.downvotes) {
                    adown = a.downvotes.length;
                }

                let bup = 0;
                let bdown = 0;

                if (b.upvotes) {
                    bup = b.upvotes.length;
                }

                if (b.downvotes) {
                    bdown = b.downvotes.length;
                }

                let avotes = aup - adown;
                let bvotes = bup - bdown;

                return bvotes - avotes;
            });

            $.each(other_issues, function (i, el) {
                var $clone = $templateOtherIssuesItem.clone();
                $clone.attr('data-index', el.id);
                $clone.find('.data-question').text(el.question);

                let voted = false;
                let upvotes = 0;
                let downvotes = 0;

                if (el.upvotes) {
                    voted = el.upvotes.indexOf(AppData.profile.id) > -1;
                    upvotes = el.upvotes.length;
                }

                if (el.downvotes) {
                    if (!voted) {
                        voted = el.downvotes.indexOf(AppData.profile.id) > -1;
                    }
                    downvotes = el.downvotes.length;
                }

                $clone.find(".vote-num").html(upvotes - downvotes);

                if (voted) {
                    $clone.find(".vote-up-btn").remove();
                    $clone.find(".vote-down-btn").remove();
                    $clone.find(".voted").html(Language.getItem("already-voted"));
                }

                $clone.appendTo($fragment);
            });

            vote_cont.html($fragment);
        } else {
            Layout.hide("issues-vote");
            $("#issues .vote-issues").hide();
        }
    }

    function renderDetails(is_mod) {

        if (is_mod === undefined) {
            is_mod = false;
        }

        if (!('issues' in localState)) {
            localState.issues = {
                itemIndex: 0
            };
        }

        var
            $self = $('#issues-details'),
            $header = $('#issues-details .data-label'),
            $details = $('#issues-details article'),
            data = AppData.issues.find(function (el) {
                return el.id === localState.issues.itemIndex
            }) || [];
        $header.text(data.title || Language.getItem('issues-new'));
        $details.find('input, textarea, select').val('').prop("disabled", is_mod);
        $details.find('.save').toggleClass('hidden', is_mod);
        $details.find('.selectSpeaker').closest('fieldset').toggleClass('hidden', true);
        $details.find(".cmc").toggle(data.length === 0);
        $.each(data, function (i, el) {
            var item = $details.find('[name=' + i + ']');
            item.val(el || '');
        });
        if(data.answer) {
            $details.find(".issues-answer").text(data.answer);
            $details.find("#issues-answer").show();
        } else {
            $details.find("#issues-answer").hide();
        }
        if (AppData.modulsettings.qandashowanonym) {
            $details.find(".is_anonym").parent().show();
        } else {
            $details.find(".is_anonym").parent().hide();
        }

        if ('input' in localState.issues) {
            $.each(localState.issues.input, function (name, value) {
                $details.find('[name="' + name + '"]').val(value);
                $details.find('.save').removeClass('hidden');
            });
        }
        if(localState.issues.source != undefined) {
            data.agenda = localState.issues.itemIndex || "";
        }
        buildOptionsAgenda($self, data);
        if (data.speaker) {
            buildOptionsSpeakers($self, data);
        }
    }

    /* ----- Build Options for Agenda -------------------------------------------------------------------------------- */
    function buildOptionsAgenda($self, data) {
        var fragmentAgenda = $(document.createDocumentFragment());
        /* Build Agenda Options */
        if (AppData.modulsettings.qandashowgeneral) {
            $('<option value="general" selected data-lang="issues-general"></option>').appendTo(fragmentAgenda);
        } else {
            $('<option value="" selected>--</option>').appendTo(fragmentAgenda);
        }
        /* Build Speakers Options */
        $.each(AppData.days, function (iday, elday) {
            if (elday.active) {
                var agenda = AppData.agenda.filter(function (aitem) {
                    return elday.id == aitem.day && aitem.show_new_question_button;
                });
                if (agenda.length > 0) {
                    var optgroup = $('<optgroup/>');
                    optgroup.attr("label", elday.name[localState.lang]);
                    $.each(agenda, function (i, el) {
                        var atitle;
                        if (typeof el.title == "string") {
                            atitle = el.title;
                        } else {
                            atitle = el.title[localState.lang];
                        }
                        if (AppData.modulsettings.qandashowtime) {
                            atitle = (el.timeStart ? el.timeStart + " - " : "") + atitle;
                        }

                        $('<option/>').val(el.id).text(atitle).appendTo(optgroup);
                    });
                    optgroup.appendTo(fragmentAgenda);
                }
            }
        });
        /* Append Fragment */
        $self.find('.selectAgenda').html(fragmentAgenda);
        /* Select Options */
        let selected;
        if (AppData.modulsettings.qandashowgeneral) {
            selected = data.agenda || 'general';
        } else {
            selected = data.agenda;
        }

        $self.find('.selectAgenda').val(selected);
        var selectTitle = Language.getItem('issues-general');
        var $el = $('[data-lang="issues-general"]');
        if ($el.is('input[type=text],input[type=search]')) {
            $el.attr('placeholder', selectTitle);
        } else {
            $el.text(selectTitle);
        }
        fragmentAgenda = null;
    }

    function buildOptionsSpeakers($self, data) {
        var fragmentSpeakers = $(document.createDocumentFragment());
        /* Build Agenda Options */
        $('<option value="" selected data-lang="issues-no-speaker"></option>').appendTo(fragmentSpeakers);
        var
            agenda = AppData.agenda.find(function (el) {
                return el.id === $self.find('.selectAgenda').val();
            });
        var hideSpeakerSelect = true;
        var speakers = '';
        if (agenda) {
            if (typeof agenda.speakers == "string") {
                speakers = (agenda.speakers || '').split(',');
            } else {
                speakers = agenda.speakers || '';
            }
            $.each(speakers, function (i, val) {
                var speaker = AppData.speakers.find(function (el) {
                    return el.id === val; //&& el.pubProfile === true
                });
                if (speaker) {
                    hideSpeakerSelect = false;
                    $('<option/>')
                        .val(speaker.id)
                        .text(speaker.name[localState.lang])
                        .appendTo(fragmentSpeakers);
                }
            });
        }
        $self.find('.selectSpeaker').closest('fieldset').toggleClass('hidden', hideSpeakerSelect);
        /* Append Fragment */
        $self.find('.selectSpeaker').html(fragmentSpeakers);
        /* Auto-Select Option if only 1 available */
        if (speakers.length === 1) {
            $self.find('.selectSpeaker').val(speakers[0]);
        }
        /* Select Options */
        if (data) {
            $self.find('.selectSpeaker').val(data.speaker);
        }
        var selectTitle = Language.getItem('issues-no-speaker');
        var $el = $('[data-lang="issues-no-speaker"]');
        if ($el.is('input[type=text],input[type=search]')) {
            $el.attr('placeholder', selectTitle);
        } else {
            $el.text(selectTitle);
        }
        fragmentSpeakers = null;
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getIssues'
        }, function (data) {
            AppData.issues = data;
            renderList();
            renderDetails();
        });
    }

    function Build() {
        renderList();
        renderDetails();
    }

    function SetHappening(data) {
        AppData.agenda = data;
        renderList();
        renderDetails();
    }

    function evtSelectChange(e) {
        buildOptionsSpeakers($('#issues-details'));
    }

    function evtClickItem(e) {
        localState.issues = {
            itemIndex: String($(this).data('index')) || 0
        };
        renderDetails(e.data.mod);
        var id;

        /*var running = AppData.agenda.filter(function (el) {
            return el.show_new_question_button;
        });

        if (running.length > 0 && !e.data.id) {
            id = running[0].id;
        }*/

        if (e.data.id) {
            id = e.data.id;
        }

        writeDbLog("view", localState.issues.itemIndex, "user_logs", "issues");

        if (id) {
            $('#issues-details').find('.selectAgenda').val(id || 'general').trigger("change");
        }

        maxCharacterCount();

        Layout.show('issues-details');
    }

    function evtSubmitForm(e) {
        e.preventDefault();
        var
            $self = $(this),
            formData = $self.serializeObject();
        /*if ( formData.question === '' ) {
          var noQuestion = Language.getItem( 'issues-no-question' );
          Modal.alert( noQuestion );
          return;
        }*/
        formData.active = true;
        $self.addClass('disabled');

        writeDbLog("submit", "", "user_logs", "issues");

        if (formData.agenda == "") {
            Modal.alert( Language.getItem( 'issues-select-agenda' ) );
            $self.removeClass('disabled');
            return;
        }

        if (formData.agenda !== 'general' && formData.agenda !== "") {
            var agenda = AppData.agenda.find(function (el) {
                return el.id === formData.agenda;
            });
            formData.room_id = agenda.rooms || '';
        } else {
            if (AppData.modulsettings.automoderate != "off") {
                formData.room_id = AppData.modulsettings.automoderate;
            }
        }
        $.post('api/', {
            do: 'saveIssues',
            data: formData
        }, function (res) {
            AppData.issues = res;
            renderList();
            if(AppData.settings.homeScreen === "floating") {
                $("#" + localState.layout[localState.layout.length - 2]).show();
                $("#" + localState.layout[localState.layout.length - 2]).attr("style", "background-color: transparent");
            }
            Layout.hide('issues-details');
            $self.removeClass('disabled');

            localState.issues.input = {};
            $('#issues-details article').find('textarea').val('');
            maxCharacterCount();

            Socket.Send('Issues.BuildFromSource', true, {
                group: ['admin']
            });

            //Socket.Send('Moderate.BuildFromSource', true);
            Socket.Send("refreshModule", {
                module: ['Moderate'],
                appdata: 'issues',
                data: res,
                callback: ['renderList']
            }, {client: 'client'});
        });
    }

    function evtInputBackup() {
        var $this = $(this);
        if ($this.get(0).hasAttribute('name')) {
            localState.issues.input = localState.issues.input || {};
            localState.issues.input[$this.attr('name')] = $this.val();
        }
    }

    function showDetails(id) {
        if (id) {
            localState.issues.source = "agenda";
            if (localState.issues != undefined) {
                if (id !== localState.issues.itemIndex || localState.issues.itemIndex === 0) {
                    localState.issues.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.issues = { 'itemIndex': String(id)};
            }
            Layout.show('issues-details');
        }
    }

    function vote(e) {
        let direction = e.data.direction;
        let id = $(this).closest(".item").attr("data-index");

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'voteIssues',
                id: id,
                direction: direction
            },
            success: function (response) {
                AppData.issues = response;
                renderList();
                renderDetails();
                //Socket.Send("Moderate.BuildFromSource", null);

                Socket.Send("refreshModule", {
                    module: ['Moderate'],
                    appdata: 'issues',
                    data: response,
                    callback: ['renderList']
                }, {client: 'client'});
            }
        });
    }

    function maxCharacterCount() {
        let charactercountlimit = AppData.modulsettings.charactercountlimit;
        let id = $("#issues-details");
        if(charactercountlimit !== "" && charactercountlimit !== "0") {
            id.find("textarea[name=question]").attr("maxlength",charactercountlimit);
            id.find('.cmcmessage').html(Language.getItem("issues-characters-can-be-entered"));
            let cmc = id.find('textarea[name=question]').val();
            let cmcl = charactercountlimit - cmc.length;
            id.find('.uptocmc').html(cmcl > 0 ? cmcl : "0");
            id.find(".cmc").show();
        } else {
            id.find("textarea[name=question]").removeAttr("maxlength");
            id.find(".cmc").hide();
        }
    }

    $(document)
        .on('click', '#issues .item', {mod: true}, evtClickItem)
        .on('click', '#issues .new', {mod: false}, evtClickItem)
        .on('change', '#issues-details .selectAgenda', evtSelectChange)
        .on('input', '#issues-details input, #issues-details textarea', evtInputBackup)
        .on('keyup', '#issues-details textarea[name=question]', maxCharacterCount)
        .on('submit', '#issues-details form', evtSubmitForm)
        .on('click', '#issues-vote .vote-up-btn', {direction: "up"}, vote)
        .on('click', '#issues-vote .vote-down-btn', {direction: "down"}, vote)
        .on("click", '#issues .vote-issues', function(){
            Layout.show("issues-vote");
        });
    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        Build: Build,
        SetHappening: SetHappening,
        renderDetails: renderDetails,
        renderList: renderList,
        showDetails: showDetails,
        evtClickItem: evtClickItem,
        maxCharacterCount: maxCharacterCount
    }
})();
